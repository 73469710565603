.input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .input {
    margin-bottom: 12px;
    width: 32%;
    margin-right: 2%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.radio-holder {
  display: flex;
  margin-bottom: 12px;
  .label {
    margin-right: 32px;
    font-size: 14px;
  }
  .radio {
    margin-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    accent-color: #807037;
  }
  .radio-label {
    overflow: visible;
    margin: 0 0 0 8px;
  }
}

.title {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 500;
}

.remove-cta {
  margin: 32px 0 4px auto;
  background: #ff504b !important;
  color: #fff !important;
  border: none !important;
  height: 28px;
  &:hover {
    background: #f23645 !important;
  }
}

.add-education-cta {
  margin: 32px auto 0;
  background: #fff !important;
  color: #463cff !important;
  border-color: #463cff !important;
  height: 28px;
  &:hover {
    background: #bfbfef !important;
  }
}

@media (max-width: 900px) {
  .input-container {
    .input {
      margin-bottom: 8px;
      width: 48%;
      margin-right: 4%;
      &:nth-child(3n) {
        margin-right: 4%;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
