.filled-button,
.outlined-button {
  height: 38px;
  padding: 12px 8px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 400ms;
  white-space: nowrap;
  user-select: none;
}

.filled-button {
  border: 1px solid #d5bb5c;
  background: #d5bb5c;
  color: black;
  &:hover {
    border: 1px solid #eee4be;
    background: #eee4be;
  }
}

.outlined-button {
  border: 1px solid #848484;
  color: #000;
  background: #fff;
  &:hover {
    background: #dfdfdf;
  }
}

.disabled-button {
  cursor: unset;
  border: 1px solid transparent;
  background: #b3b3b3;
  &:hover {
    border: 1px solid transparent;
    background: #b3b3b3;
  }
}
