.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .input {
    width: 48%;
    margin-bottom: 12px;
  }
}

@media (max-width: 600px) {
  .input-container {
    .input {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
