.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .input {
    width: 32%;
    margin-bottom: 12px;
  }
}

.uploader-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .uploader-holder {
    margin-top: 20px;
    width: 48%;
    .label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    .uploader {
      height: 200px;
    }
  }
}

.highest-qualification-tag {
  background: #8f00ff1a;
  width: fit-content;
  color: #8f00ff;
  font-size: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
  padding: 4px 12px;
}

.remove-cta {
  margin: 32px 0 4px auto;
  background: #ff504b !important;
  color: #fff !important;
  border: none !important;
  height: 28px;
  &:hover {
    background: #f23645 !important;
  }
}

.add-education-cta {
  margin: 32px auto 0;
  background: #fff !important;
  color: #463cff !important;
  border-color: #463cff !important;
  height: 28px;
  &:hover {
    background: #bfbfef !important;
  }
}

@media (max-width: 900px) {
  .input-container {
    .input {
      width: 48%;
      margin-bottom: 8px;
    }
  }

  .uploader-container {
    .uploader-holder {
      margin-top: 12px;
      width: 49%;
    }
  }
}

@media (max-width: 600px) {
  .uploader-container {
    .uploader-holder {
      margin-top: 12px;
      width: 100%;
    }
  }
}
