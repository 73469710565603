.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .status-holder {
    width: 52%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 36px;
    .status-name {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .status-icon {
      width: 120px;
      height: 120px;
    }
    .status-text {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
  .banner,
  .form {
    height: 100%;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 36px;
  }
  .form {
    width: 52%;
    padding: 60px;
    background: #fff;
  }
}

@media (max-width: 900px) {
  .container {
    .banner,
    .form {
      padding: 24px;
    }
    .form,
    .status-holder {
      padding: 32px;
    }
  }
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
    .banner,
    .form,
    .status-holder {
      height: 40%;
      width: 100%;
      padding: 20px;
    }
    .form,
    .status-holder {
      width: 100%;
      height: 60%;
    }
  }
}
