.label {
  color: #000;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-password,
.input,
.input-error {
  width: 100%;
  margin: 0;
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  border: 2px solid #b3b3b3;
  &:active,
  &:focus-visible {
    border: 2px solid #463cff;
  }
  &::placeholder {
    color: #b3b3b3;
    font-size: 14px;
  }
  &::-ms-input-placeholder {
    color: #b3b3b3;
    font-size: 14px;
  }
}

.input-error {
  border: 2px solid #ec221f;
  &:active,
  &:focus-visible {
    border: 2px solid #ec221f;
  }
}

.validation-error {
  color: #ec221f;
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%) !important;
}

.input-password {
  padding-right: 32px;
}

.show-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  cursor: pointer;
}

@media (max-width: 600px) {
  .label {
    font-size: 12px;
    margin-bottom: 6px;
  }
}
