.modal-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}

.close-button {
  top: -20px;
  right: 36px;
}

.close-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.img-modal-root {
  z-index: 1000000;
}

.modal-img-holder-container {
  min-height: 500px;
  max-height: 600px;
  min-width: 800px;
  max-width: 1000px;
  background: none;
  box-shadow: none;
  padding: 0;
  overflow: visible;
}

.modal-image-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  position: relative;

  .image-controls {
    display: flex;
    height: 100%;
    align-items: center;
    align-self: flex-start;
  }

  .control-icon {
    margin-right: 12px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    padding: 6px;
  }

  .control-icon-disabled {
    opacity: 0.6;
  }

  .disabled-control {
    cursor: default;
  }

  .arrow-icon-left,
  .arrow-icon-right {
    margin: 16px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-54%);
  }

  .arrow-icon-left {
    left: -12px;
  }

  .arrow-icon-right {
    right: -12px;
  }

  .draggable-image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 720px;
    min-height: 420px;
    margin: 20px 0;
    overflow: hidden;
    transform: translate(0px, 0px);
    width: 700px;
    height: 500px;
    border-radius: 8px;
    background-color: #232323;
    user-select: none;
  }

  .image-draggable {
    cursor: move;
    overflow: visible;
  }

  .cover-image {
    max-height: 500px;
    object-fit: contain;
    max-width: 700px;
  }

  .actions-holder {
    display: flex;
    min-width: 700px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
  }

  .reset-button,
  .disabled-reset-button {
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    background: transparent !important;
  }

  .disabled-reset-button {
    opacity: 0.6;
  }

  .primary-btn {
    outline: none;
    border-radius: 20px;
    width: 190px;
    text-align: center;
    line-height: 1;
    padding: 12px;
    border: 1px solid #4285f4;
  }

  .image-count {
    color: #ffffff;
    font-size: 12px;
  }
}
