.modal {
  border-radius: 8px;
  .modal-title {
    font-size: 18px;
    font-weight: 600;
  }
  .modal-cta-holder {
    margin: 20px auto 0;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .modal-cta {
      width: 40%;
    }
  }
}

.greeting {
  font-size: 20px;
}

.title {
  font-size: 32px;
  margin-bottom: 8px;
  font-weight: 700;
}

.subtitle {
  color: #757575;
  font-size: 14px;
  margin-bottom: 16px;
}

.input {
  width: 100%;
  margin: 8px 0;
}

.login-button {
  width: 100%;
  margin-top: 24px;
}

.not-joining-cta {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}

.error-text {
  margin-top: 12px;
  font-size: 12px;
  color: #ec221f;
  height: 18px;
  text-align: center;
  overflow: visible;
}

@media (max-width: 600px) {
  .title {
    font-size: 24px;
    margin-bottom: 4px;
    font-weight: 700;
  }

  .subtitle {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .not-joining-cta {
    margin-top: 12px;
    font-size: 12px;
  }

  .error-text {
    margin-top: 8px;
    font-size: 10px;
    height: 12px;
  }
}
