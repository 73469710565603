.container {
  width: 80%;
  margin: 40px auto;
  .company-holder {
    margin-bottom: 12px;
    width: 100%;
    .company-name {
      font-weight: 700;
      font-size: 48px;
    }
    .company-description {
      color: #9a9a9a;
      font-size: 12px;
      height: 34px;
      overflow: scroll;
    }
  }

  .form-holder {
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      justify-content: space-between;
      .name {
        font-size: 20px;
        font-weight: 700;
      }
      .cta-holder {
        display: flex;
        align-items: center;
        .cta {
          height: 20px;
          margin-left: 8px;
          width: 80px;
        }
      }
    }
  }

  .tab-container {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    .tabs-holder {
      display: flex;
      align-items: center;
      .tab,
      .selected-tab,
      .completed-tab {
        padding: 8px;
        border-bottom: 1px solid #dcdcdc;
        color: #848484;
        font-size: 12px;
        width: 20%;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
      }
      .selected-tab {
        border-bottom: 2px solid #d5bb5c;
        background: #f5f5f5;
        color: #161513;
        font-weight: 500;
      }
      .completed-tab {
        cursor: pointer;
        &:hover {
          background: #f5f5f5;
        }
      }
      .completed-icon {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }
    }
    .tab-content {
      padding: 16px;
      overflow-y: scroll;
      max-height: calc(100vh - 292px);
    }
  }
}

@media (max-width: 900px) {
  .container {
    width: 90%;
    .tab-container {
      .tabs-holder {
        .tab,
        .selected-tab,
        .completed-tab {
          font-size: 10px;
        }
        .completed-icon {
          width: 14px;
          height: 14px;
          margin-left: 6px;
        }
      }
      .tab-content {
        padding: 12px;
        max-height: calc(100vh - 292px);
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    width: 96%;
    .company-holder {
      margin-bottom: 8px;
      .company-name {
        font-weight: 700;
        font-size: 32px;
      }
      .company-description {
        font-size: 10px;
        height: 30px;
      }
    }

    .tab-container {
      .tab-content {
        padding: 12px;
        max-height: calc(100vh - 264px);
      }
    }
  }
}
