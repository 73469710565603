.label {
  color: #000;
  font-size: 14px;
  margin: 4px 0 8px;
}

.date-picker {
  width: 100%;
  margin: 0;
  font-size: 14px;
  outline: none;
  border: none;
  padding: 6px;
}

.date-picker-wrapper {
  width: 100%;
  margin: 0;
  outline: none;
  border: 2px solid #b3b3b3;
  border-radius: 4px;
  div {
    display: block;
    input {
      &::placeholder {
        color: #b3b3b3;
        font-size: 14px;
      }
      &::-ms-input-placeholder {
        color: #b3b3b3;
        font-size: 14px;
      }
    }
  }
}
