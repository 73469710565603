.container {
  background: #0a4834;
  position: relative;
  .top-left-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 149px;
  }
  .bottom-right-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 307px;
  }

  .title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 40px;
    color: #fff;
  }
  .subtitle {
    color: #9a9a9a;
    font-size: 14px;
  }

  .not-joining-cta {
    margin-top: 32px;
    width: fit-content;
  }
}

.list {
  padding-inline-start: 12px;
  .list-item {
    color: #fff;
    font-size: 16px;
    margin-bottom: 8px;
  }
}

@media (max-width: 600px) {
  .container {
    .top-left-icon {
      width: 80px;
    }
    .bottom-right-icon {
      width: 140px;
    }
    .subtitle {
      font-size: 12px;
    }
    .title {
      font-size: 32px;
    }
  }
  .list {
    .list-item {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}
