.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .uploader-holder {
    margin-bottom: 16px;
    width: calc(50% - 8px);
    .label {
      margin-bottom: 8px;
      font-size: 14px;
    }
    .uploader {
      height: 186px;
    }
  }
}

.uploader-holder {
  .label {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .uploader {
    height: 186px;
    background: #fff;
  }
}

.input-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .input {
    width: 32%;
    .otp-input {
      width: 100%;
      margin-top: 8px;
    }
    .otp-cta {
      margin-top: 8px;
      width: 120px;
    }
  }
}

.verified-text,
.error-text {
  margin-top: 4px;
  font-size: 12px;
  color: #ec221f;
  display: flex;
  align-items: center;
}

.completed-icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.verified-text {
  color: #21ad64;
}

.edit-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 12px;
}

.resend-cta {
  font-size: 10px;
  cursor: pointer;
  margin-left: 12px;
  color: #463cff;
}

.additional-doc-holder {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0;
  background: #f5f5f5;
  padding: 40px 8px 12px;
  border-radius: 8px;
  position: relative;
  .uploader-holder {
    width: calc(50% - 8px);
  }
  .input {
    width: calc(50% - 8px);
  }
}

.remove-cta {
  position: absolute;
  right: 8px;
  top: 8px;
  background: #ff504b !important;
  color: #fff !important;
  border: none !important;
  height: 28px;
  &:hover {
    background: #f23645 !important;
  }
}

.select-additional {
  margin-top: 20px;
  width: 32%;
}

@media (max-width: 900px) {
  .input-container {
    .input {
      width: 48%;
      .otp-cta {
        margin: 8px 0 12px;
      }
    }
  }

  .select-additional {
    width: 48%;
  }

  .container {
    .uploader-holder {
      margin-bottom: 12px;
      width: calc(50% - 4px);
      .label {
        margin-bottom: 8px;
        font-size: 12px;
      }
      .uploader {
        height: 186px;
      }
    }
  }
}

@media (max-width: 600px) {
  .input-container {
    .input {
      width: 100%;
    }
  }

  .select-additional {
    width: 100%;
  }

  .container {
    .uploader-holder {
      width: 100%;
    }
  }

  .additional-doc-holder {
    flex-direction: column;
    margin: 20px 0;
    .uploader-holder {
      width: 100%;
    }
    .input {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
