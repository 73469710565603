.uploaded-holder {
  border: 1px dotted #baabab;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  position: relative;
}
.delete-photo {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  width: 20px;
  height: 22px;
  background: #b3b3b3;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background: #9a9a9a;
  }
}
.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}
.uploader-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .uploader-text {
    margin: 20px 0 12px;
    color: #0b0b0b;
    font-size: 14px;
  }
  .or-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    .line {
      border-top: 1px solid #e7e7e7;
      width: 60px;
    }
    .or-text {
      font-size: 12px;
      color: #6d6d6d;
      margin: 0 8px;
    }
  }
  .upload-error {
    color: #ec221f;
    font-size: 14px;
    margin-top: 32px;
  }
}
